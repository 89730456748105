<template>
    <div>
        <el-card class="card flex-center">
            <div class="center">
                <div class="list">
                    <div class="row flex-align" v-for="(item, index) in signInToList" :key="item.id">
                        <el-button
                            type="warning"
                            size="small"
                            class="btn"
                            style="margin-right: 20px;"
                            @click="acSign(item, 'print')"
                            >打印</el-button
                        >
                        <el-button
                            type="primary"
                            size="small"
                            class="btn"
                            style="margin-right: 20px;"
                            v-if="item.status != 3"
                            @click="acSign(item)"
                            >签到</el-button
                        >
                        <el-button style="margin-right: 20px;" v-else class="btn" type="info" size="small"
                            >已签到</el-button
                        >
                        {{ item.name }}
                    </div>
                </div>
                <div class="flex-col">
                    <div style="width: 852px;">
                        <el-input
                            class="input"
                            v-model="key"
                            size="large"
                            @change="setKey"
                            @focus="toPrint"
                            placeholder="请确保光标在输入框"
                        ></el-input>
                        <!-- <el-button type="primary" size="default" @click="selectPrinter"
            >选择打印机</el-button
          > -->
                        <el-select v-model="toSel" placeholder="获取失败请重新刷新页面">
                            <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                            <div slot="empty">
                                <div style="color:#999;padding:20px">
                                    获取失败请重新刷新页面
                                </div>
                            </div>
                        </el-select>
                    </div>
                </div>

                <div style="width: 100%;height: 1px;     clear: both;"></div>
                <!-- {{ toSelName }} -->
                <el-autocomplete
                    v-model="phone"
                    size="medium"
                    @focus="focus"
                    @input="input"
                    :fetch-suggestions="querySearch"
                    style="width: 540px;margin-bottom: 10px;"
                    placeholder="请输入参会者手机号"
                >
                    <template slot-scope="{ item }">
                        <div class="name">{{ item.realName || item.nickname }}({{ item.phone }})</div>
                    </template>
                    <el-button slot="append" icon="el-icon-search" @click="toSearch">查询报名信息</el-button>
                </el-autocomplete>
                <div style="width: 852px;margin-bottom: 10px;">
                    <el-descriptions title="签到用户信息" :column="2" border :labelStyle="{ width: '120px' }">
                        <el-descriptions-item label="用户名">{{ userInfo.realName }}</el-descriptions-item>
                        <el-descriptions-item label="公司">{{ userInfo.company }}</el-descriptions-item>
                        <el-descriptions-item label="职位">{{ userInfo.job }}</el-descriptions-item>
                        <el-descriptions-item label="备注">
                            {{ userInfo.remark }}
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
                <div class="tip">{{ message }}</div>
                <div class="line" style="width: 100%;"></div>
                <!-- <hr /> -->
                <div style="margin-bottom: 30px;">
                    <div class="title">
                        输入本次举办的活动组团
                    </div>
                    <div class="desc">
                        勾选的表示当前可签到活动
                    </div>
                    <fuzzy-selection
                        type="4"
                        tipName="请输入当前可扫码签到活动组团中的其中一场活动"
                        all
                        :value.sync="acId"
                        @focus="focus"
                        @input="input"
                        width="100%"
                    ></fuzzy-selection>
                </div>
                <el-checkbox-group v-model="checkboxGroup" size="small">
                    <div class="op-list">
                        <div class="op-item" v-for="item in acGroup"  :key="item.id">
                            <el-checkbox :label="item.id" style="display: flex;position: relative;height: 50px;">
                              {{ item.title }}
                              <span style="position: absolute;top: 25px;left: 20px;color:#606266">票种：</span>
                              <el-checkbox-group  style="position: absolute;top: 25px;left: 66px" v-model="item.checkList">
                               <el-checkbox v-for="i in item.tickets" :label="i.id">{{i.name}}</el-checkbox>
                              </el-checkbox-group></el-checkbox>
                        </div>
                        <el-button class="clear-btn" type="primary" size="small" @click="clear">清除</el-button>
                    </div>
                </el-checkbox-group>
                <div class="foot">
                    <div class="first-tip">首次使用请按以下步骤安装软件：</div>
                    <div>
                        第一步，<a
                            href="https://static-cdn.yfchuhai.com/www.yfchuhai.com/uploads/exe/printingMachineDrive.exe"
                            >请先下载打印机驱动并安装</a
                        >
                    </div>
                    <div>
                        第二步，<a
                            href="https://static-cdn.yfchuhai.com/www.yfchuhai.com/uploads/exe/CLodop_Setup_for_Win64NT_4.161EN.exe"
                            >点击下载打印机控件并安装</a
                        >
                    </div>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import { debounce } from "@/utils/methods.js";
import fuzzySelection from "../../components/fuzzySelection.vue";
var CreatedOKLodopObject, CLodopIsLocal, CLodopJsState;

//加载CLodop时用双端口(http是8000/18000,而https是8443/8444)以防其中某端口被占,
//主JS文件名“CLodopfuncs.js”是固定名称，其内容是动态的，与其链接的打印环境有关:
function loadCLodop() {
    if (CLodopJsState == "loading" || CLodopJsState == "complete") return;
    CLodopJsState = "loading";
    var head = document.head || document.getElementsByTagName("head")[0] || document.documentElement;
    var JS1 = document.createElement("script");
    var JS2 = document.createElement("script");

    if (window.location.protocol == "https:") {
        JS1.src = "https://localhost.lodop.net:8443/CLodopfuncs.js";
        JS2.src = "https://localhost.lodop.net:8444/CLodopfuncs.js";
    } else {
        JS1.src = "http://localhost:8000/CLodopfuncs.js";
        JS2.src = "http://localhost:18000/CLodopfuncs.js";
    }
    JS1.onload = JS2.onload = function() {
        CLodopJsState = "complete";
    };
    JS1.onerror = JS2.onerror = function(evt) {
        CLodopJsState = "complete";
    };
    head.insertBefore(JS1, head.firstChild);
    head.insertBefore(JS2, head.firstChild);
    CLodopIsLocal = !!(JS1.src + JS2.src).match(/\/\/localho|\/\/127.0.0./i);
}
export default {
    data() {
        return {
            key: "",
            keyValue: "",
            time: null,
            checkboxGroup: [],
            ticketsGroup: [
            ],
            acId: 0,
            activityId: 0,
            acGroup: [],
            message: "",
            signInToList: [],
            post: "",
            phone: "",
            list: [],
            toSel: "",
            toSelName: "",
            userInfo: {},
        };
    },
    created() {
        loadCLodop();
    },
    components: {
        fuzzySelection,
    },
    watch: {
        acId(news) {
            //   console.log(news)
            if (!news) return;
            this.activityId = news;
            this.getList(news);
        },

      acGroup: {
        handler(){
          console.log(134)
          localStorage.setItem(
              "acGroup",
              JSON.stringify({
                acGroup: this.acGroup,
                list: this.checkboxGroup,
                acId: this.activityId,
              })
          );
        },
        deep:true
      },
        checkboxGroup(val) {
            localStorage.setItem(
                "acGroup",
                JSON.stringify({
                    acGroup: this.acGroup,
                    list: this.checkboxGroup,
                    acId: this.activityId,
                })
            );
            if (!val.length) return;
        },
        toSel(val) {},

    },
    methods: {
        toPrint() {
            //   if (!this.key) {
            //     return
            //   }
            //   this.init()
            clearInterval(this.time);
            this.time = setInterval(() => {
                $(".input input").focus();
            }, 800);
        },
        async querySearch(queryString, cb) {
            let { data: res } = await this.$http.get(
                `/admin/User/getSearchList?page=1&pageSize=10&keyword=${queryString}&order=&type=&status=&waId=&showCircles=&certType=&business=&completeInfo=&ugId=&jobLevel=&regDate=&certDate=&province=&city=&bindEnterprise=`
            );
            var list = res.data.list.map((item) => {
                return {
                    ...item,
                    value: item.phone,
                };
            });
            cb(list);
        },
        setKey(newValue) {
            if (!newValue) return;
            this.init();
        },
        selectPrinter() {
            //   if (LODOP.CVERSION) {
            //     LODOP.On_Return = (TaskID, Value) => {
            //       if (Value >= 0) {
            //         alert('选择成功!')
            //         this.toSel = Value
            //         this.toSelName = LODOP.GET_PRINTER_NAME(Value)
            //       } else alert('选择失败！')
            //     }
            //     LODOP.SELECT_PRINTER()
            //     return
            //   }
        },
        thePrinter() {
            var num = LODOP.GET_PRINTER_COUNT();
            this.list = [];
            //   return
            for (let index = 0; index < num; index++) {
                this.list.push({
                    id: index,
                    name: LODOP.GET_PRINTER_NAME(index),
                });
            }
            this.toSelName = LODOP.GET_PRINTER_NAME(-1);
            this.toSel = this.list.find((item) => item.name == this.toSelName).id;
        },
        async toSearch(e, val) {
            var arr = this.acGroup.map((item) => {
                return {
                    id: item.id,
                    selected: this.checkboxGroup.includes(item.id) ? 1 : 0,
                  tickets:item.checkList.join(','),

                };
            });
            const { data } = await this.$http.get("/admin/Activity/getSignListByPhone", {
                params: {
                    phone: val || this.phone,
                    activityStr: JSON.stringify(arr),
                },
            });
            this.post = this.phone;
            this.signInToList = data.data;
        },
        async acSign(val, type) {
            var arr = this.acGroup.map((item) => {
                return {
                    id: item.id,
                    selected: this.checkboxGroup.includes(item.id) ? 1 : 0,
                    tickets:item.checkList.join(','),

                };
            });
            const { data } = await this.$http.post("/admin/Activity/sign", {
                id: val.id,
                activityId: this.activityId,



            });
            if (this.post) {
                this.toSearch("$event", this.post);
            } else {
                this.getFormList();
            }
            this.userInfo = data.data.user;
            if (type) {
                if (data.errorCode == 200) {
                    var { data: res } = await this.$http.get("/api/Activity/activityQrCode?code=" + data.data.code);
                    // this.key = ''
                    this.print(res.data);
                }
            }
        },
        async getFormList(val) {
            var arr = this.acGroup.map((item) => {
                return {
                    id: item.id,
                    selected: this.checkboxGroup.includes(item.id) ? 1 : 0,
                  tickets:item.tickets.join(',')
                };
            });
            const { data: res } = await this.$http.get("/admin/Activity/getSignListByStr", {
                params: {
                    id: val || this.keyValue,
                    activityStr: JSON.stringify(arr),
                },
            });
            //   console.log(res)
            if (res.errorCode == 200) {
                // this.$message.success(res.message)
                this.post = "";
                this.signInToList = res.data;
            }
        },
        async getList(val) {
            const { data: res } = await this.$http.get("/admin/Activity/getGroupList", {
                params: {
                    id: val,
                },
            });
            this.acGroup = res.data.map(item=>{
              item.checkList=[]
              return item
            });

        },
        input() {
            clearInterval(this.time);
        },
        focus() {
            clearInterval(this.time);
        },
        clear() {
            this.checkboxGroup = [];
            this.acGroup = [];
            localStorage.setItem(
                "acGroup",
                JSON.stringify({
                    acGroup: this.acGroup,
                    list: this.checkboxGroup,
                    acId: this.activityId,
                })
            );
        },
        init: debounce(async function() {
            var arr = this.acGroup.map((item) => {
                return {
                    id: item.id,
                    selected: this.checkboxGroup.includes(item.id) ? 1 : 0,
                  tickets:item.checkList.join(','),

                };
            });
            try {
                const { data } = await this.$http.post("/admin/Activity/sign", {
                    id: this.key,
                    activityId: this.activityId,
                    activityStr: JSON.stringify(arr),
                });
                // this.key = ''

                this.message = data?.message;
                this.userInfo = data?.data?.user || {};
                if (data.errorCode == 200) {
                    var { data: res } = await this.$http.get("/api/Activity/activityQrCode?code=" + data.data.code);
                    this.keyValue = this.key;

                    this.getFormList(this.keyValue);
                    this.key = "";
                    this.print(res.data);
                    return;
                }
                this.keyValue = this.key;
                this.getFormList(this.keyValue);
                this.key = "";
            } catch (error) {
                console.log(error);
            }
        }),
        print(val) {
            if (!val) return;
            if (!LODOP && document.readyState !== "complete") {
                alert("请重新打印");
            } else {
                //另外一种规格的纸
                // LODOP.PRINT_INIT("begin");
                // LODOP.SET_PRINT_PAGESIZE(1, 900, 600);
                // LODOP.ADD_PRINT_TEXT("40.48mm","15.61mm","23.55mm","6.35mm","马化腾");
                // LODOP.SET_PRINT_STYLEA(0,"FontName","黑体");
                // LODOP.SET_PRINT_STYLEA(0,"FontSize",13);
                // LODOP.SET_PRINT_STYLEA(0,"Angle",90);
                // LODOP.ADD_PRINT_IMAGE("10.58mm","30.69mm","45.77mm","42.33mm","(<img src=\"./1e22a6a46165b79b51b086992c5461b8.jpeg\"/>)");
                // LODOP.SET_PRINT_STYLEA(0,"Stretch",2);

                //透明7cm*5cm的纸
                // LODOP.SET_LICENSES('', 'F97073C655FECF3B75E720D03E4A5E787B8', '', '')
                LODOP.PRINT_INIT("begin");
                LODOP.SET_PRINT_PAGESIZE(1, 700, 500);
                LODOP.ADD_PRINT_IMAGE(
                    "2.65mm",
                    "15.88mm",
                    "77.52mm",
                    "36.78mm",

                    //   `(<img src=\"${'https://activity-1305822803.cos.ap-beijing.myqcloud.com/pagc2022/images/code2.png'}\"/>)`
                    `(<img style="width:400px;height:400px" src="${val.url}"/>)`
                );
                LODOP.SET_PRINT_STYLEA(0, "Stretch", 2);
                LODOP.ADD_PRINT_TEXT(
                    "39.95mm",
                    "25.93mm",
                    "60mm",
                    "5.29mm",
                    //   '萨迪基金法是的fdefedf'
                    val.user.realName || val.user.nickname
                );
                LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                LODOP.SET_PRINT_STYLEA(0, "Horient", 2);
                LODOP.SET_PRINT_STYLEA(0, "FontName", "黑体");
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                LODOP.SET_LICENSES("", "F97073C655FECF3B75E720D03E4A5E787B8", "", "");
                // LODOP.PREVIEW()
                LODOP.SET_PRINTER_INDEX(this.toSel);

                LODOP.PRINT();
                // this.getFormList(this.keyValue)
            }
        },
    },
    computed: {},
    destroyed() {
        clearInterval(this.time);
    },

    mounted() {
        // window.On_CLodop_Opened = function() {
        //   print()
        //   window.On_CLodop_Opened = null
        // }
        if (localStorage.getItem("acGroup")) {
            // console.log(localStorage.getItem('acGroup'));
            var form = JSON.parse(localStorage.getItem("acGroup"));
            //   console.log(form)
            if (form.list && form.acGroup) {
                this.checkboxGroup = [...form.list];
                this.acGroup = [...form.acGroup];
                this.activityId = form.acId;
            }

            //   console.log(this.acGroup);
        }
        setTimeout(() => {
            this.thePrinter();
        }, 800);
        this.time = setInterval(() => {
            $(".input input").focus();
        }, 800);
    },
};
</script>

<style lang="less" scoped>
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.center {
    text-align: center;
}
.input {
    width: 600px;
    /deep/.el-input__inner {
        border: 1px solid red;
    }
}
.card {
    min-height: 800px;
}
.foot {
    margin-top: 70px;
    line-height: 1.8;
    font-size: 14px;
}
.line {
    height: 1px;
    background: #e7e7e7;
    margin-bottom: 80px;
    margin-top: 80px;
}
.op-item {
    margin: 5px 0;
}
.op-list {
    text-align: left;
}
.tip {
    font-size: 25px;
}
.list {
    width: 100%;
    text-align: left;
    margin-bottom: 30px;
    .row {
        margin: 10px 0;
    }
}
.title {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 10px;
}
.desc {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
}
.btn {
    width: 65px;
}
.first-tip {
    text-align: left;
    width: 228px;
    display: inline-block;
}
.clear-btn {
    margin-top: 10px;
}
</style>
